.navbar {
  display: flex;
  align-items: center;
}
.navbar li {
  list-style: none;
}
.navbar li a {
  color: #fff;
  padding: 0 10px;
  text-decoration: none;
  font-size: 15px;
  font-weight: 500;
  margin-left: 5px;
  transition: 0.3s ease-in-out;
}
.navbar li a:hover {
  color: #eee;
}
.btnSignin,
.btnSignin2 {
  padding: 10px 20px;
  border: 2px solid #fff;
  font-family: inherit;
  background-color: transparent;
  color: #fff;
  border-radius: 100px;
  font-weight: 600;
  margin-left: 10px;
  cursor: pointer;
  transition: 0.3s ease-in-out;
}
.btnSignin2 {
  margin-left: 0;
}
.navbar li button:hover {
  background-color: #fff;
  color: #000;
}
.btnSignOut {
  display: flex;
  align-items: center;
  background: transparent;
  color: #fff;
  font-family: inherit;
  font-weight: 600;
  border: 2px solid #fff;
  font-size: 14px;
  padding: 8px 13px;
  border-radius: 100px;
  cursor: pointer;
}
.lockIcon {
  transform: translateY(-1px);
}
.boxName {
  background-color: var(--pepper-red);
  width: 35px;
  padding: 5px 0;
  border-radius: 100px;
  margin: 0 15px;
  cursor: pointer;
  text-align: center;
}
.boxName span {
  color: #fff;
  font-size: 16px;
  font-weight: 600;
}
.infoCardBox {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: #00000059;
  z-index: 1000;
}
.infoCardContainer {
  position: absolute;
  top: 0;
  right: 0;
  width: 23%;
  height: 100vh;
  background-color: #000;
  padding: 30px 20px;
}
.infoCardContainer h2 {
  color: #eee;
  font-weight: 600;
  font-size: 22px;
}
.infoText {
  text-align: center;
  margin-bottom: 20px;
}
.infoText2 {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #3b3a3a;
  padding: 10px 0;
  margin-bottom: 15px;
}
.infoText {
  color: #eee;
}
.iconText {
  color: #7c7979;
  margin-right: 10px;
}
.iconText1 {
  color: #cecaca;
  font-size: 13px;
  font-weight: 600;
}
.menuIcon {
  color: #000;
  font-size: 20px;
  background-color: #eeee;
  border-radius: 10px;
  padding: 7px;
  margin-left: 10px;
  cursor: pointer;
}
.menuContentBox {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: #00000079;
  z-index: 6000;
}
.menuContent {
  position: fixed;
  top: 0;
  right: 0;
  width: 30%;
  height: 100vh;
  background-color: #000;
  z-index: 80000;
}
.menuTexth2 {
  text-align: center;
}
.menuTexth2 h2 {
  color: #eee;
  padding: 15px 0;
}
.menuTexth2 {
  border-bottom: 1px solid #615f5f;
}
.menuNavItems {
  padding: 10px 20px;
  border-bottom: 1px solid #333232;
}
.menuNavItems a {
  color: #eee;
  text-decoration: none;
  font-size: 13px;
}
.menuIcon {
  display: none;
}
@media screen and (max-width: 923px) {
  .navbar li a {
    display: none;
  }
  .menuIcon {
    display: block;
  }
  .navbar li button {
    font-size: 13px;
  }
}

@media screen and (max-width: 866px) {
  .infoCardContainer {
    width: 40%;
  }
}

@media screen and (max-width: 597px) {
  .infoCardContainer {
    width: 60%;
  }
  .menuContent {
    width: 70%;
  }
  .menuTexth2 h2 {
    font-size: 16px;
  }
}

@media screen and (max-width: 500px) {
  .navbar li button {
    padding: 8px 20px;
    font-size: 11px;
  }
  .btnNavBox {
    display: none;
  }
  .boxName {
    margin: 0 6px;
  }
}

@media screen and (max-width: 424px) {
  .infoCardContainer {
    width: 70%;
  }
}
