.courseSampleCard {
  padding: 10px;
  transition: all 0.3s ease-in-out;
}
.courseSampleCard:hover {
  cursor: pointer;
  transform: translateY(-10px);
}
.courseSampleMainText h3 {
  color: #fff;
  font-weight: 500;
  font-size: 15px;
}
.courseSampleMainText p {
  display: block;
  color: #fff;
  font-size: 11px;
}
.courseSampleCard img {
  width: 100%;
}
.btnBox {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 8px 0;
}
.btnBox button {
  padding: 8px 15px;
  border-radius: 100px;
  cursor: pointer;
  font-family: inherit;
  font-weight: 600;
}
.btnBox1 {
  background-color: #fff;
  color: #000;
}
.btnBox2 {
  background-color: transparent;
  color: #fff;
  border: 2px solid #fff;
}
.btnIcon {
  font-size: 10px;
  margin-right: 5px;
}

/* MEDIA QUERIES */
@media screen and (max-width: 765px) {
  .courseSampleCard img {
    width: 100%;
  }
}
