.textInputBox {
  margin: 10px 0;
}
.inputMain textarea {
  width: 95%;
  padding: 8px 10px;
  font-size: 14px;
  font-family: inherit;
  border: none;
  font-weight: 500;
  color: #c7c6c6;
  background-color: #000;
}
.inputMain textarea:focus {
  border: none;
  outline: none;
}
.inputMain {
  margin-bottom: 10px;
}
.textInputBox label {
  font-size: 14px;
  font-weight: 600;
  color: #b9b8b8;
}
.inputMain {
  display: flex;
  align-items: center;
  border: 2px solid #6b6a6a;
  border-radius: 5px;
  padding-right: 15px;
}
.iconSize {
  font-size: 24px;
  cursor: pointer;
  color: #eee;
}
.hide {
  display: none;
}
