.termsContainer {
  background-color: #eee;
  padding: 30px;
}
.termsBox {
  background-color: #fff;
  padding: 20px;
  border-radius: 3px;
}
.termsBox1 h2 {
  font-size: 22px;
}
.termBox2 {
  margin: 20px 0;
}
.termBox2 h2 {
  font-size: 15px;
}
.termBox2 p {
  font-size: 14px;
  margin: 10px 0;
}

@media screen and (max-width: 600px) {
  .termsBox1 h2 {
    font-size: 16px;
  }
  .termsBox1 p {
    font-size: 12px;
  }
  .termsContainer {
    padding: 10px 15px;
  }
  .termBox2 p {
    font-size: 12px;
    margin: 10px 0;
  }
  .termBox2 h2 {
    font-size: 14px;
  }
}
