.loaderContainer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: #141414f6;
  z-index: 3000;
  color: #fff;
}
.loaderBox {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
}
.loaderBox span {
  font-size: 14px;
  margin-left: 12px;
}
.loader,
.loader:after {
  border-radius: 50%;
  width: 5em;
  height: 5em;
}
.loader {
  font-size: 5px;
  text-indent: -9999em;
  border-top: 0.8em solid var(--pepper-red);
  border-right: 0.8em solid var(--pepper-red);
  border-bottom: 0.8em solid var(--pepper-red);
  border-left: 0.8em solid transparent;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 0.7s infinite linear;
}
@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@media screen and (max-width: 500px) {
  .loaderBox span {
    font-size: 12px;
    margin-left: 12px;
  }
  .loader,
  .loader:after {
    width: 4em;
    height: 4em;
  }
  .loader {
    font-size: 4px;
  }
}
