.previewContainer {
  background-color: #252525;
  padding: 30px 15px;
}
.previewContainer {
  display: flex;
}
.imgBoxContain {
  width: 100%;
  margin-right: 40px;
}
.imgBoxContain img {
  width: inherit;
  border-radius: 10px;
}
.imgBoxContain video {
  width: 100%;
}
.containerText {
  width: 100%;
}
.containerPtext {
  width: 100%;
}
.containerText h2 {
  color: #fff;
  font-size: 23px;
  margin-bottom: 20px;
}
.containerText p {
  color: #eee;
  font-weight: 300;
  font-size: 13px;
}
.containerPtext {
  font-size: 14px;
}
.learnBox li {
  color: #eee;
  font-size: 13px;
}
.learnBoxText {
  margin-top: 15px;
  margin-bottom: 5px;
  font-size: 14px;
}
.priceBox {
  margin: 20px 0;
}
.priceBox p {
  font-weight: 600;
  font-size: 18px;
}
.boxCourse1 {
  background-color: #303030;
  padding: 40px;
}
.boxCourse21 {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;
}
.boxCourse2 {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 20px;
}
.courseImg {
  position: relative;
}
.courseImg img {
  display: block;
  width: 100%;
  border-radius: 10px;
  opacity: 0.4;
  cursor: pointer;
}
.courseImg video {
  width: 100%;
}
.imgIcon {
  font-size: 30px;
  color: #fff;
  position: absolute;
  top: 0;
  left: 0;
}
.boxIcons {
  display: flex;
  align-items: center;
  color: #fff;
  margin-bottom: 20px;
}
.boxIcons p {
  margin-left: 5px;
}
.button {
  width: 100%;
  background-color: var(--pepper-red);
  padding: 14px 20px;
  border: none;
  font-weight: 700;
  font-family: inherit;
  border-radius: 5px;
  font-size: 14px;
  color: #fff;
  cursor: pointer;
}
.button:hover {
  background-color: var(--pepper-red-light);
}
.courseVideoText h2 {
  color: #fff;
  font-size: 16px;
  font-weight: 500;
}
.unlockBox {
  display: flex;
}
.unlockBox p {
  color: #eee;
  font-size: 13px;
  margin-left: 5px;
}
.icon {
  color: rgb(21, 129, 21);
  font-size: 35px;
}
.mentText h2 {
  margin: 0;
}
.selectInputBox {
  margin-bottom: 10px;
}
.selectInputBox select,
.selectInputBox1 select {
  width: 100%;
  padding: 15px 10px;
  font-size: 14px;
  font-family: inherit;
  font-weight: 500;
  background-color: #000;
  border: 2px solid #6b6a6a;
  border-radius: 3px;
  color: #c7c6c6;
}
.selectInputBox select:focus {
  outline: none;
}
.selectInputBox option {
  background-color: #000;
}
.head2 h4 {
  color: #cfcbcb;
}
.boxContainbx {
  width: 45%;
  margin: 10px auto;
}
.mentText {
  text-align: center;
}
.radioType {
  display: flex;
  align-items: center;
  padding: 10px 0;
}
.radioType input {
  width: 20px;
  height: 20px;
}
.radioType label {
  display: inline-block;
  transform: translateY(-5px);
  margin-right: 10px;
}
.radi1 {
  color: #fff;
}
.radi2 {
  color: #fff;
  margin-left: 20px;
}
.checkbx {
  display: flex;
  padding-bottom: 16px;
}
.checkbx p {
  font-size: 12px;
  color: #c7c6c6;
  margin-left: 10px;
}
.choosePix {
  background-color: transparent;
  padding: 10px 20px;
  border: 1px solid #6b6a6a;
  text-align: center;
  border-radius: 3px;
  margin-bottom: 20px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}
.icon {
  font-size: 25px;
  color: #ada9a9;
}
.choosePix span {
  display: block;
  font-size: 14px;
  font-weight: 600;
  color: #ada9a9;
}
.choosePix:hover {
  background-color: #252525;
}
.profilePixBox img {
  display: inline-block;
  width: 90px;
  height: 90px;
  object-fit: contain;
}
.profileImg {
  text-align: center;
}
.profileImg img {
  display: inline-block;
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 100px;
}
.profileBox1 {
  width: 45%;
  margin: 10px auto;
  background-color: #000;
  padding: 20px 30px;
}
.profileBox2 {
  padding: 10px 0;
  border-bottom: 1px solid #383636;
}
.profinfo1Tex1 {
  font-weight: 700 !important;
  font-size: 12px !important;
}
.profinfo1Tex2 {
  font-size: 14px !important;
}
.profileBox1 h2 {
  text-align: center;
}
button {
  margin: 10px 0;
}
.modalBox {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: #000000a4;
  z-index: 10000;
}
.modalBoxContent {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 45%;
  height: 80vh;
  padding: 20px;
  background-color: #fff;
  z-index: 20000;
  border-radius: 5px;
  overflow: scroll;
}
.modalBoxContent h2 {
  text-align: center;
}
.formMainInput {
  margin: 10px 0;
}
.formMainInput label {
  display: block;
  font-size: 13px;
  font-weight: 600;
}
.formMainInput input,
.formMainInput select,
.formMainInput textarea {
  width: 90%;
  padding: 13px 10px;
  border: 1px solid #ccc;
  font-family: inherit;
  border-radius: 3px;
}
.formMainInput select {
  width: 94%;
}
.formMainInput input:focus {
  border: 1px solid #ccc;
  outline: none;
}
.formMainInput p {
  font-size: 13px;
}
.profileEditBox {
  display: flex;
  align-content: center;
  justify-content: space-between;
}
.profileEditBox a {
  display: inline-block;
  background-color: var(--pepper-red);
  font-family: inherit;
  padding: 8px 10px;
  border-radius: 100px;
  color: #eee;
  text-decoration: none;
  font-size: 12px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  margin: 10px 0;
}
.profileEditBox button:hover {
  background-color: var(--pepper-red-light);
}
.profileEditBox1 {
  display: flex;
  align-items: center;
}
.profileEditBox1 p {
  font-weight: 600;
  color: #eee;
}

.modal {
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  background-color: #000000a8;
  z-index: 3000;
}
.modalContent {
  position: fixed;
  width: 40%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  padding: 20px;
  z-index: 5000;
  border-radius: 3px;
}
.modalContent h2 {
  font-size: 20px;
  text-align: center;
}
.imgBox {
  text-align: center;
}
.imgBox img {
  display: block;
  width: 60%;
  margin: 24px auto;
}
.btnBoxSub {
  text-align: center;
}
.btnBoxSub button {
  padding: 13px 20px;
  font-family: inherit;
  border-radius: 100px;
  cursor: pointer;
  border: none;
}
.btnBoxSub1 {
  display: inline-block;
  margin-right: 10px;
  background-color: var(--pepper-red);
  color: #fff;
  transition: all 0.2s ease-in-out;
}
.btnBoxSub1:hover {
  background-color: var(--pepper-red-light);
}
.modalContent p {
  text-align: center;
}

@media screen and (max-width: 1198px) {
  .imgBoxContain img {
    width: 100%;
    border-radius: 20px;
  }
}

@media screen and (max-width: 827px) {
  .boxCourse1 {
    padding: 20px;
  }
  .previewContainer {
    flex-direction: column;
  }
  .boxCourse2 {
    grid-template-columns: repeat(3, 1fr);
  }
  .boxCourse21 {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width: 603px) {
  .boxCourse21 {
    grid-template-columns: repeat(1, 1fr);
  }
  .courseVideoText h2 {
    font-size: 15px;
  }
  .boxContainbx {
    width: 100%;
    margin: 10px auto;
  }
  .mentText {
    text-align: unset;
  }
  .profileBox1 {
    width: 80%;
    margin: 0;
  }
  .modalBoxContent {
    width: 80%;
  }
}

@media screen and (max-width: 600px) {
  .modalContent {
    width: 85%;
  }
  .imgBox img {
    width: 80%;
  }
  .btnBoxSub button {
    font-size: 13px;
  }
  .modalContent h2 {
    font-size: 15px;
  }
  .modalContent p {
    font-size: 12px;
  }
}

@media screen and (max-width: 444px) {
  .boxCourse2 {
    grid-template-columns: repeat(2, 1fr);
  }
  .containerText h2 {
    font-size: 17px;
    font-weight: 600;
    margin-bottom: 5px;
  }
  .containerPtext {
    font-size: 12px;
    font-weight: 300;
  }
  .learnBoxText {
    font-size: 12px;
  }
  .learnBox li {
    font-size: 12px;
    margin: 8px 0;
  }
  .button {
    font-weight: 500;
    font-size: 12px;
  }
  .checkbx p {
    font-size: 11px;
  }
  .radioType label {
    font-size: 13px;
  }
  .profinfo1Tex2 {
    font-size: 13px !important;
  }
}
