.whatsappLive {
  position: fixed;
  bottom: 0;
  right: 0;
  margin-right: 10px;
  margin-bottom: 10px;
}
.whatsappLive a {
  text-decoration: none;
  color: #000;
}
.whatsappBoxImg img {
  width: 60px;
}
.whatsappBox {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.whatsappBox span {
  font-size: 16px;
  margin-right: 5px;
  font-weight: 600;
}
@media screen and (max-width: 500px) {
  .whatsappBoxImg img {
    width: 34px;
  }
  .whatsappBox {
    background-color: #fff;
    border-radius: 100px;
    padding: 6px 20px;
  }
  .whatsappBox span {
    font-size: 14px;
    margin-right: 5px;
    font-weight: 600;
  }
}
