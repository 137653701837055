.courseSampleBox {
  padding: 70px 40px;
  background-color: #000;
}
.courseCardBox {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  margin: 25px 0;
}
.courseSampleHeadText {
  text-align: center;
}
.courseSampleHeadText h2 {
  color: #fff;
}
.courseSampleHeadText p {
  color: #fff;
  width: 60%;
  margin: 10px auto;
}
.btnSamp {
  text-align: center;
}
.btnSamp button {
  width: 30%;
}
/*MEDIA QUERIES */
@media screen and (max-width: 1268px) {
  .courseCardBox {
    grid-template-columns: repeat(4, 1fr);
  }
}
@media screen and (max-width: 1093px) {
  .courseSampleBox {
    padding: 60px 15px;
  }
}
@media screen and (max-width: 1001px) {
  .courseCardBox {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media screen and (max-width: 765px) {
  .courseCardBox {
    grid-template-columns: repeat(2, 1fr);
  }
  .courseSampleHeadText p {
    width: 100%;
  }
  .btnSamp button {
    width: 90%;
  }
}
@media screen and (max-width: 532px) {
  .courseCardBox {
    grid-template-columns: repeat(1, 1fr);
  }
  .courseSampleHeadText p {
    font-size: 12px;
    font-weight: 400;
    margin: 5px auto;
  }
  .courseSampleHeadText h2 {
    font-size: 17px;
    font-weight: 600;
  }
}
