.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 40px;
  background: var(--black);
}
.logoText {
  color: #fff;
}
.headerH2 {
  font-weight: 800;
  font-size: 25px;
  line-height: 15px;
  letter-spacing: 0.7px;
}
.headerP {
  font-size: 14px;
  font-weight: 500;
}
.spanText {
  background: var(--gradient);
}
.anchor {
  text-decoration: none;
}
.anchor:focus {
  text-decoration: none;
}
/*MEDIA QUERIES */
@media screen and (max-width: 1254px) {
  .header {
    padding: 20px 20px;
  }
}

@media screen and (max-width: 458px) {
  .header {
    padding: 15px 15px;
  }
  .logoText {
    transform: translateY(5px);
  }
  .headerH2 {
    font-size: 18px;
    letter-spacing: 2.3px;
  }
  .headerP {
    font-size: 11px;
  }
}
