.btnBox button {
  width: 100%;
  background: var(--pepper-red);
  padding: 14px 20px;
  border: none;
  font-weight: 500;
  font-family: inherit;
  border-radius: 5px;
  font-size: 14px;
  color: #fff;
  cursor: pointer;
}
.btnBox button:hover {
  background-color: var(--pepper-red-light);
}
