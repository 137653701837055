.prodContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 75px 40px;
}
.prodImg,
.prodText {
  width: 50%;
}
.prodImg img {
  display: block;
  width: 70%;
  height: 300px;
  object-fit: contain;
}
.prodText h2 {
  font-size: 30px;
}
.prodText1 {
  font-size: 20px;
  font-weight: 700;
  margin: 10px 0;
}
.desc {
  margin: 15px 0;
}
.btnBox {
  margin-top: 20px;
  margin-bottom: 40px;
}
.btnBox p {
  font-size: 13px;
  font-weight: 600;
  margin: 7px 0;
}
.btnBox button {
  display: inline-block;
  font-family: inherit;
  padding: 5px 10px;
  font-size: 15px;
  font-weight: 600;
  cursor: pointer;
  border-radius: 10px;
  border: 1px solid #eee;
}
.btnBox span {
  margin: 0 15px;
  font-weight: 600;
}
.billHeader h2 {
  font-size: 19px;
  font-weight: 600;
}
.billHeader p {
  font-size: 14px;
  font-weight: 400;
}
.billForm {
  margin: 10px 0;
}
.billForm label {
  display: block;
  font-size: 13px;
  font-weight: 600;
}
.billForm input {
  width: 90%;
  font-family: inherit;
  border: 2px solid #eee;
  padding: 13px;
  border-radius: 5px;
}
.billForm input:focus {
  border: 2px solid #ccc;
  outline: none;
}
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: #000000a9;
}
.modalContent {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  width: 45%;
  padding: 20px;
  border-radius: 5px;
}
.orderMain {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.orderMain {
  margin: 20px 0;
}
.orderText {
  width: 50%;
}
.orderSumBox h2 {
  text-align: center;
}
.orderMain img {
  width: 200px;
  height: 180px;
  object-fit: contain;
}
.orderText1 {
  font-size: 20px;
  font-weight: 700;
  margin: 5px 0;
}
.orderText2,
.orderText3 {
  font-weight: 500;
  font-size: 14px;
  margin: 5px 0;
}
.backBtn {
  font-weight: 500;
  color: #302e2e;
  cursor: pointer;
}
@media screen and (max-width: 700px) {
  .modalContent {
    width: 90%;
  }
}

@media screen and (max-width: 998px) {
  .prodText h2 {
    font-size: 24px;
  }
  .prodText1 {
    font-size: 18px;
  }
  .desc p {
    font-size: 14px;
  }
  .btnBox button,
  .btnBox span {
    font-size: 15px;
  }
}
@media screen and (max-width: 742px) {
  .prodContainer {
    flex-direction: column;
  }
  .prodText {
    width: 100%;
  }
}
@media screen and (max-width: 536px) {
  .prodContainer {
    flex-direction: column;
    padding: 20px 15px;
  }
  .prodText {
    width: 100%;
  }
  .prodImg img {
    width: 100%;
    height: 190px;
  }
  .orderMain {
    flex-direction: column;
  }
  .orderMain img {
    width: 100px;
    height: 100px;
  }
  .orderText {
    width: 100%;
  }
  .orderSumBox h2 {
    font-size: 17px;
  }
  .orderText1 {
    font-size: 16px;
  }
}
