.courseMain {
  padding: 10px 30px;
  border-top: 1px solid #5c5b5bcc;
  background-color: #0000;
}
.courseCardBox {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
}
.courseSampleHeadText {
  text-align: center;
}
.courseSampleHeadText h2 {
  color: #fff;
}
.courseSampleHeadText p {
  color: #fff;
  width: 60%;
  margin: 10px auto;
}
.courseMain h2 {
  color: #eee;
  padding: 0 10px;
  margin: 10px 0;
  font-weight: 600;
}

/*MEDIA QUERIES */
@media screen and (max-width: 1268px) {
  .courseCardBox {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media screen and (max-width: 1093px) {
  .courseSampleBox {
    padding: 60px 15px;
  }
}

@media screen and (max-width: 1001px) {
  .courseCardBox {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media screen and (max-width: 765px) {
  .courseCardBox {
    grid-template-columns: repeat(2, 1fr);
  }
  .courseSampleHeadText p {
    width: 100%;
  }
  .courseMain {
    padding: 20px 13px;
  }
  .courseMain h2 {
    margin-top: 10px;
  }
}

@media screen and (max-width: 532px) {
  .courseCardBox {
    grid-template-columns: repeat(1, 1fr);
  }
  .courseSampleHeadText p {
    font-size: 14px;
  }
  .courseMain h2 {
    font-size: 17px;
  }
}
