.testimonialCardBox {
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 18px 50px -10px;
  border-radius: 5px;
  padding: 15px 20px;
}
.testimonialCardImg img {
  display: inline-block;
  width: 80px;
  height: 70px;
  border-radius: 15px;
  object-fit: contain;
  object-position: center;
}
.cardText {
  font-size: 14px;
}
.icon {
  color: #ccc;
}
.imgRatings {
  text-align: right;
}
.imgRatings p {
  font-weight: 600;
  font-size: 14px;
  color: #423f3f;
  line-height: 10px;
}
.imgRatings img {
  width: 16px;
}
/* MEDIA QUERIES */
@media screen and (max-width: 1235px) {
  .testimonialCardBox p {
    font-size: 14px;
  }
}

@media screen and (max-width: 731px) {
  .testimonialCardImg {
    text-align: center;
  }
}

@media screen and (max-width: 560px) {
  .cardText p {
    font-size: 12px;
  }
}
