.probBox {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 60px 40px;
}
.prodBoxText {
  width: 70%;
}
.prodBoxImg {
  width: 50%;
  margin-left: 40px;
  text-align: center;
  border-radius: 10px;
  padding: 20px;
}
.prodBoxImg img {
  border-radius: 10px;
}
.prodBoxText h2 {
  margin: 10px 0;
  font-weight: 500;
}
.prodpBox {
  margin: 18px 0;
}
.probBox p {
  font-size: 14px;
}
.prodBoxImg p {
  margin-top: 10px;
}
.textBox1 {
  font-size: 15px;
  line-height: 8px;
  font-weight: 600;
}
@media screen and (max-width: 959px) {
  .prodBoxImg img {
    width: 40%;
  }
  .prodBoxText {
    width: 60%;
  }
}
@media screen and (max-width: 848px) {
  .probBox {
    flex-direction: column;
  }
  .prodBoxImg {
    width: 90%;
    margin-top: 20px;
  }
  .prodBoxImg img {
    width: 30%;
  }
  .prodBoxText {
    width: 90%;
  }
}

@media screen and (max-width: 624px) {
  .probBox {
    padding: 20px 15px;
    align-items: unset;
  }
  .prodBoxImg {
    padding: 10px;
    text-align: center;
    margin-left: 0;
  }
  .prodBoxImg,
  .prodBoxText {
    width: 100%;
  }
  .prodBoxImg img {
    width: 50%;
    margin: 0 auto;
  }
  .prodBoxText h2 {
    font-size: 18px;
    text-align: center;
  }
  .probBox p {
    font-size: 12px;
  }
  .prodBoxText h2 {
    margin: 5px 0;
  }
}
