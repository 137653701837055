.box1 {
  text-align: center;
}
.solutionBox {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;
  padding: 80px 40px;
  background-color: #1f1c1c;
}
.box h2 {
  text-align: center;
}
.boxT {
  margin: 0 20px;
}
.boxMain1 {
  background-color: #0f0f0f;
  padding: 20px;
}
.boxMain1 h2 {
  color: #fff;
  text-align: center;
  font-size: 18px;
  font-weight: 600;
}
.boxMain1 p {
  color: #eee;
  margin: 10px 0;
  font-size: 15px;
}

@media screen and (max-width: 901px) {
  .boxMain1 p {
    font-size: 13px;
  }
  .boxMain1 h2 {
    font-size: 16px;
  }
}

@media screen and (max-width: 800px) {
  .boxMain1 p {
    font-size: 13px;
  }
  .boxMain1 h2 {
    font-size: 16px;
  }
  .solutionBox {
    grid-template-columns: repeat(2, 1fr);
    padding: 80px 15px;
  }
}

@media screen and (max-width: 521px) {
  .solutionBox {
    grid-template-columns: repeat(1, 1fr);
    padding: 80px 15px;
  }
  .boxMain1 h2 {
    font-size: 15px;
  }
  .boxMain1 p {
    font-size: 12px;
  }
}
