article {
  width: 100%;
  position: relative;
  box-sizing: border-box;
  border-radius: 10px;
}
article .line {
  width: 100%;
  height: 40px;
  background: #bbb;
  margin: 20px 0;
  border-radius: 5px;
}

article .shimmer {
  position: absolute;
  top: 0;
  left: 0;
  width: 50%;
  height: 100%;

  background: linear-gradient(
    100deg,
    rgba(255, 255, 255, 0) 20%,
    rgba(255, 255, 255, 0.5) 50%,
    rgba(255, 255, 255, 0) 80%
  );

  animation: shimmer 1s infinite linear;
}

@keyframes shimmer {
  from {
    transform: translateX(-200%);
  }
  to {
    transform: translateX(200%);
  }
}
