.introbox {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 85px 40px;
  position: relative;
  isolation: isolate;
}
.introbox::after {
  content: " ";
  position: absolute;
  z-index: -1;
  inset: 0;
  background: #fffefe;
  opacity: 0.85;
}
.introTextBox h2 {
  font-weight: 800;
  font-size: 28px;
  line-height: 35px;
}
.introTextBox p {
  font-weight: 400;
  font-size: 15px;
}
.introTextBox button {
  width: 70%;
  background: var(--pepper-red);
  padding: 14px 20px;
  border: none;
  font-weight: 700;
  font-family: inherit;
  border-radius: 5px;
  font-size: 15px;
  color: #fff;
  cursor: pointer;
}
.introBoxIcon {
  display: flex;
  align-items: center;
  line-height: 0;
  margin: 12px 0;
}
.introText1 {
  line-height: 20px;
  margin: 10px 0;
}
.icon {
  margin-right: 10px;
  font-size: 20px;
}
.introImg {
  display: block;
  width: 50%;
  height: 380px;
  object-fit: contain;
  border-radius: 5px;
}
.introTextBox {
  width: 50%;
}
.videoBox {
  width: 50%;
}
.videoBox video {
  width: 100%;
}
/*MEDIA QUERIES */
@media screen and (max-width: 1254px) {
  .introbox {
    padding: 50px 20px;
  }
  .introTextBox {
    width: 100%;
  }
  .videoBox {
    width: 100%;
  }
  .videoBox video {
    width: 100%;
  }
  .introTextBox h2 {
    font-size: 25px;
    line-height: 30px;
  }
  .introTextBox p {
    font-size: 15px;
    width: 100%;
  }
  .introTextBox button {
    font-size: 14px;
  }
}

@media screen and (max-width: 974px) {
  .introTextBox h2 {
    font-size: 20px;
    line-height: 23px;
  }
  .introTextBox {
    width: 90%;
  }
  .videoBox {
    width: 90%;
  }
}

@media screen and (max-width: 923px) {
  .introbox {
    flex-direction: column;
    align-items: center;
    padding: 40px 20px;
    height: auto;
  }
  .introTextBox h2 {
    text-align: center;
    font-size: 30px;
    line-height: 30px;
  }
  .videoBox video {
    width: 100%;
    display: block;
  }
  .introImg {
    display: block;
    width: 100%;
    height: 300px;
    border-radius: 5px;
    object-fit: cover;
  }
  .introTextBox button {
    margin-bottom: 20px;
    width: 100%;
  }
  .btnBox {
    text-align: center;
  }
}
@media screen and (max-width: 627px) {
  .introTextBox {
    width: 100%;
  }
  .videoBox {
    width: 100%;
  }
  .introImg {
    display: block;
    width: 100%;
    height: 190px;
    border-radius: 5px;
    object-fit: cover;
  }
}
@media screen and (max-width: 458px) {
  .introbox {
    padding: 30px 15px;
  }
  .introTextBox h2 {
    font-size: 22px;
    line-height: 25px;
  }
  .introTextBox p {
    font-weight: 400;
    font-size: 13px;
  }
  .introTextBox button {
    font-size: 13px;
    font-weight: 500;
  }
}
