.storeContainer {
  background-color: #292828;
}
.storeBox {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 20px;
  padding: 60px 40px;
}

@media screen and (max-width: 1133px) {
  .storeBox {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media screen and (max-width: 949px) {
  .storeBox {
    padding: 60px 15px;
    grid-gap: 15px;
  }
}

@media screen and (max-width: 834px) {
  .storeBox {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width: 581px) {
  .storeBox {
    grid-template-columns: repeat(1, 1fr);
    padding: 30px 15px;
  }
}
