:root {
  --black: #000000;
  --white: #fff;
  --pepper-red: #f10707;
  --pepper-red-light: rgb(219, 26, 26);
  --bg-dark-grey: #111010;
}
* {
  margin: 0;
  padding: 0;
}
body {
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}
