.homeBoxMovie {
  padding: 30px 40px;
}
.homeMoviesBox {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;
}
.h2MovieBox {
  text-align: center;
  margin: 20px 0;
}
.homeMoviesBox img {
  width: 100%;
  border-radius: 5px;
  cursor: pointer;
}
.homeBoxMovie button {
  width: 35%;
}
.btnBoxWatch {
  text-align: center;
  margin: 20px 0;
}

@media screen and (max-width: 693px) {
  .homeMoviesBox {
    grid-template-columns: repeat(2, 1fr);
  }
  .homeBoxMovie {
    padding: 20px;
  }
  .homeMovie3 {
    display: none;
  }
  .homeBoxMovie button {
    width: 80%;
  }
  .btnBoxWatch {
    margin: 0;
  }
}

@media screen and (max-width: 500px) {
  .homeMoviesBox {
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 10px;
  }
  .h2MovieBox {
    margin: 10px 0;
  }
  .h2MovieBox h2 {
    font-size: 20px;
  }
  .h2MovieBox p {
    font-size: 13px;
  }
  .homeBoxMovie button {
    width: 100%;
  }
}
