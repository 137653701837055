.testimonialBox {
  padding: 50px 40px;
  background-color: #fff;
  transform: translateY(-10px);
  position: relative;
  isolation: isolate;
}
.testimonialBox::after {
  content: " ";
  position: absolute;
  z-index: -1;
  inset: 0;
  background: #fffefe;
  opacity: 0.84;
}
.testimonialTextHeader {
  text-align: center;
}
.testimoniaalBoxCard {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 20px;
  margin: 20px 0;
}

/* MEDIA QUERIES */
@media screen and (max-width: 1093px) {
  .testimonialBox {
    padding: 40px 15px;
  }
}

@media screen and (max-width: 958px) {
  .testimoniaalBoxCard {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media screen and (max-width: 731px) {
  .testimoniaalBoxCard {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media screen and (max-width: 532px) {
  .testimoniaalBoxCard {
    grid-template-columns: repeat(1, 1fr);
  }
  .testimonialTextHeader h2 {
    font-size: 18px;
  }
  .testimonialTextHeader p {
    font-size: 12px;
  }
}
