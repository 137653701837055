.storeCard {
  background-color: #000;
  padding: 20px;
  border-radius: 10px;
}
.storeImg {
  text-align: center;
}
.storeImg img {
  display: block;
  width: 100%;
  margin: 0 auto;
  border-radius: 10px;
}
.storeText {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 5px 0;
}
.storeText h2 {
  font-size: 15px;
  color: #eee;
}
.storeText p {
  font-weight: 500;
  font-size: 15px;
  color: #eee;
}
.storeDesc p {
  font-size: 12px;
  margin-bottom: 5px;
  color: #eee;
}

@media screen and (max-width: 500px) {
  .storeImg img {
    width: 80%;
  }
  .storeText p {
    font-size: 13px;
  }
  .storeText h2 {
    font-size: 14px;
  }
}
