.videoContainer {
  position: relative;
  width: 100%;
}
.videoClip {
  width: 100%;
  height: 50vh;
  object-fit: cover;
}

@media screen and (max-width: 1024px) {
  .videoContainer:after {
    height: 30vh;
  }
  .videoClip {
    height: 30vh;
  }
}

@media screen and (max-width: 437px) {
  .videoClip {
    height: 35vh;
  }
  .videoContainer::after {
    height: 35vh;
  }
}
