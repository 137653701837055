.btnBox {
  text-align: center;
}
.btnBox a {
  padding: 10px 20px;
  border: none;
  font-weight: 500;
  font-family: inherit;
  border-radius: 5px;
  font-size: 14px;
  color: #fff;
  cursor: pointer;
  background-color: transparent;
  margin-top: 5px;
}
