.formBox {
  background-color: #000000;
  background-image: url("../../assets/bg.svg");
  position: relative;
  isolation: isolate;
  height: auto;
}
.formBox::after {
  content: " ";
  position: absolute;
  z-index: -1;
  inset: 0;
  background: #000;
  opacity: 0.95;
}

.formContainer {
  width: 35%;
  margin: 0 auto;
  padding: 30px 20px;
}
.formContainer h2 {
  text-align: center;
  font-size: 26px;
  line-height: 25px;
  color: #fff;
  font-weight: 600;
}
.formContainer p {
  text-align: center;
  font-size: 14px;
  color: #fff;
}
.formContainer a {
  display: inline-block;
  margin-bottom: 10px;
  font-weight: 500;
  color: #fff;
  text-decoration: none;
  font-size: 15px;
}
.choosePix {
  background-color: transparent;
  padding: 10px 20px;
  border: 1px solid #6b6a6a;
  text-align: center;
  border-radius: 3px;
  margin-bottom: 10px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}
.icon {
  font-size: 25px;
  color: #ada9a9;
}
.choosePix span {
  display: block;
  font-size: 14px;
  font-weight: 600;
  color: #ada9a9;
}
.choosePix:hover {
  background-color: #252525;
}
.signBtnFlex {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.signBtnFlex button {
  padding: 10px 20px;
  border-radius: 100px;
  font-size: 15px;
  font-family: inherit;
  cursor: pointer;
}
.hide {
  display: none;
}
.profilePixBox img {
  display: inline-block;
  width: 90px;
  height: 90px;
  object-fit: contain;
}
.selectInputBox {
  margin-bottom: 20px;
}
.selectInputBox select {
  width: 100%;
  padding: 15px 10px;
  font-size: 15px;
  font-family: inherit;
  border: none;
  font-weight: 500;
  color: #c7c6c6;
  background-color: transparent;
  border: 1px solid #6b6a6a;
}
.selectInputBox select:focus {
  outline: none;
}
.selectInputBox option {
  background-color: #000;
}

/* MEDIA QUERIES */
@media screen and (max-width: 1240px) {
  .formContainer {
    width: 60%;
  }
}
@media screen and (max-width: 1085px) {
  .formContainer {
    width: 50%;
  }
}
@media screen and (max-width: 878px) {
  .formContainer {
    width: 70%;
    padding: 20px 15px;
  }
}
@media screen and (max-width: 627px) {
  .formContainer {
    width: 90%;
    padding: 20px 15px;
  }
  .formContainer h2 {
    font-size: 25px;
  }
  .formBox {
    height: 100vh;
  }
}
