.footerBox {
  background-color: #000;
  padding: 40px;
}
.nav {
  display: flex;
}
.nav a {
  padding-right: 12px;
  color: #fff;
  font-size: 15px;
  text-decoration: none;
  transition: all 0.3s ease-in-out;
}
.nav a:hover {
  color: #dad6d6;
}
.footerNav,
.footerNav2 {
  display: flex;
  justify-content: space-between;
  padding-top: 20px;
  padding-bottom: 30px;
  border-bottom: 1px solid #6b6a6a;
}
.footerNav2 {
  border-bottom: unset;
  padding-bottom: 15px;
  padding-top: 20px;
}
.socialIcon {
  color: #fff;
  margin-right: 20px;
  font-size: 22px;
}
.socialIcon12 {
  font-size: 20px;
  color: #fff;
  margin-right: 15px;
}
.socialIcons {
  display: flex;
  align-items: center;
}
.footerLogo {
  text-align: center;
  margin-top: 15px;
}
.footerLogo p {
  color: #ccc;
  font-size: small;
}
.footerLogo img {
  width: 150px;
}
.phoneLink {
  display: inline-block;
  color: #fff;
  text-decoration: none;
  font-size: 15px;
  margin-top: 10px;
}
.socialIcons span {
  color: #fff;
}
.nav p {
  color: #fff;
}
.socialIcons p {
  transform: translateY(-3px);
  color: #fff;
  margin-right: 10px;
}

/* MEDIA QUERIES */
@media screen and (max-width: 1093px) {
  .footerBox {
    padding: 40px 15px;
  }
}

@media screen and (max-width: 733px) {
  .nav a {
    font-size: 13px;
  }
  .socialIcon {
    font-size: 20px;
  }
}
@media screen and (max-width: 615px) {
  .footerNav,
  .footerNav2 {
    flex-direction: column;
  }
  .nav {
    display: block;
  }
  .nav a {
    display: inline-block;
    margin-bottom: 10px;
  }
  .footerBox {
    padding: 20px 15px;
  }
  .phoneLink {
    margin-bottom: 30px;
    font-size: 13px;
  }
  .socialIcon {
    font-size: 18px;
  }
  .socialIcons p {
    font-size: 13px;
  }
  .socialIcon12 {
    font-size: 18px;
  }
}
