.hero {
  width: 100%;
  height: 80vh;
  background: url("../../../assets/UY.jpg");
  background-size: cover;
  background-position: center;
  position: relative;
  overflow: hidden;
}
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #00000091;
}
.herotext h2 {
  color: #fff;
  font-size: 30px;
  margin-bottom: 15px;
}
.herotext {
  position: absolute;
  top: 30%;
  left: 10%;
}
.herotext button {
  width: 100%;
  background-color: var(--pepper-red);
  padding: 14px 20px;
  border: none;
  font-weight: 500;
  font-family: inherit;
  border-radius: 5px;
  font-size: 13px;
  color: #fff;
  cursor: pointer;
}

.herotext button:hover {
  background-color: var(--pepper-red-light);
}
.icon1 {
  margin-right: 10px;
}

@media screen and (max-width: 500px) {
  .hero {
    height: 40vh;
    background-position: left;
    background-size: cover;
  }
  .herotext h2 {
    font-size: 20px;
  }
}
