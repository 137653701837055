.container {
  background-color: #171717;
}
.cardBox {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;
  padding: 20px 40px;
}
.card {
  background-color: rgb(37, 37, 37);
}
.cardImg img {
  width: 100%;
}
.cardText {
  padding: 10px 20px;
  color: #eee;
}
.eachBox {
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
  border-bottom: 1px solid #3d3d3d;
  padding-bottom: 20px;
}
.cardText1 {
  border-bottom: 1px solid #3d3d3d;
  padding-bottom: 10px;
}
.cardP1 {
  font-size: 15px;
  font-weight: 600;
  margin: 5px 0;
}
.cardP2 {
  font-size: 13px;
  font-weight: 300;
  color: #c9c7c7;
}
.cardCourse,
.cardCourse2 {
  border-bottom: 1px solid #3d3d3d;
  padding-bottom: 10px;
  margin-bottom: 20px;
}
.cardCourse2 {
  margin-bottom: 20px;
}
.cardCourse span {
  display: block;
  color: #c9c7c7;
  font-size: 13px;
  margin: 10px 0;
  border: 1px solid var(--pepper-red);
  cursor: pointer;
  text-align: center;
  padding: 13px 0;
  border-radius: 100px;
}
.cardCourse2 p {
  color: #c9c7c7;
  font-size: 13px;
  margin: 5px 0;
}
.cardCourse span:hover {
  background-color: var(--pepper-red);
  color: #fff;
}
.cardVideo {
  text-align: center;
  margin-top: 20px;
}
.cardStage1 h1 {
  color: #fff;
  font-size: 18px;
  text-align: center;
  padding: 10px;
}
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: #000000a9;
}
.modalContent {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) !important;
  background-color: #fff;
  width: 45%;
  padding: 20px;
  border-radius: 5px;
}
.noticeBox {
  text-align: center;
}
.noticeBox h2 {
  color: var(--pepper-red);
}
.boxP p {
  font-size: 15px;
  margin: 10px 0;
}
.btnPs {
  display: flex;
  align-items: center;
  margin: 20px 0;
}
.btnPs button {
  padding: 14px;
  border: none;
  border-radius: 100px;
  cursor: pointer;
  font-family: inherit;
}
.btn1 {
  margin-right: 10px;
}
.btn2 {
  background-color: var(--pepper-red);
  color: #eee;
}
.loadImg {
  display: block;
  width: 50%;
  margin: 0 auto;
  border-radius: 10px;
  height: 300px;
  object-fit: cover;
}
.loadBoxImg {
  text-align: center;
}
@media screen and (max-width: 997px) {
  .cardBox {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media screen and (max-width: 735px) {
  .cardBox {
    padding: 50px 15px;
  }
}

@media screen and (max-width: 700px) {
  .modalContent {
    width: 85%;
  }
}
@media screen and (max-width: 629px) {
  .cardBox {
    padding: 20px 15px;
    grid-template-columns: repeat(1, 1fr);
  }
  .cardVideo video {
    width: 100%;
  }
  .boxP p {
    font-size: 13px;
  }
  .loadImg {
    display: block;
    width: 90%;
    margin: 0 auto;
    height: 180px;
    object-fit: cover;
  }
}
