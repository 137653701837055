.formBox {
  background-color: #000000;
  background-image: url("../../assets/bg.svg");
  position: relative;
  isolation: isolate;
  height: 100vh;
}
.formBox::after {
  content: " ";
  position: absolute;
  z-index: -1;
  inset: 0;
  background: #000;
  opacity: 0.95;
}

.formContainer {
  width: 35%;
  margin: 0 auto;
  padding: 90px 20px;
}
.formContainer h2 {
  text-align: center;
  font-size: 30px;
  color: #fff;
}
.formContainer p {
  text-align: center;
  color: #fff;
}
.formContainer a {
  display: inline-block;
  margin-bottom: 10px;
  font-weight: 500;
  color: #fff;
  text-decoration: none;
  font-size: 15px;
}

/* MEDIA QUERIES */
@media screen and (max-width: 1240px) {
  .formContainer {
    width: 60%;
  }
}
@media screen and (max-width: 1085px) {
  .formContainer {
    width: 50%;
  }
}
@media screen and (max-width: 878px) {
  .formContainer {
    width: 70%;
    padding: 90px 15px;
  }
}
@media screen and (max-width: 627px) {
  .formContainer {
    width: 90%;
    padding: 90px 15px;
  }
}
