.textInputFlex {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 5px;
  margin: 10px 0;
}
.textInputFlex input {
  padding: 15px 10px;
  font-size: 15px;
  font-family: inherit;
  border: none;
  font-weight: 500;
  color: #c7c6c6;
  background-color: #000;
  border: 2px solid #6b6a6a;
  border-radius: 3px;
}
.textInput1,
.textInput2 {
  width: 90%;
}
.textInputFlex input:focus {
  outline: none;
}
.textInputFlex label {
  display: block;
  font-size: 14px;
  font-weight: 600;
  color: #b9b8b8;
}
@media screen and (max-width: 480px) {
  .textInputFlex {
    grid-template-columns: repeat(1, 1fr);
  }
  .textInput1,
  .textInput2 {
    width: 95%;
  }
  .box1Input {
    margin-bottom: 10px;
  }
}
