.containerBox {
  padding: 10px;
  background-color: #eee;
}
.containerBox1 {
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  margin-bottom: 20px;
}
.icon {
  background-color: #eee;
  font-size: 30px;
  padding: 10px;
  border-radius: 10px;
}
.mainBox,
.mainBox1 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 20px 0;
  padding-bottom: 5px;
  border-bottom: 1px solid #ebe8e8;
}
.courseCardFile {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.courseCardFile span {
  font-size: 14px;
  cursor: pointer;
  padding: 8px 10px;
}
.courseCardFile span:hover {
  background-color: #eee;
  border-radius: 3px;
}

.imgBox img,
.imgBox4 img {
  display: inline-block;
  width: 70px;
  height: 70px;
  border-radius: 100px;
  object-fit: contain;
}
.imgBox4 {
  text-align: center;
}
.imgBox4 img {
  width: 40%;
  height: 200px;
  border-radius: 3px;
}
.boxFData p,
.boxFData1 p,
.boxFData4 p {
  font-size: 13px;
  font-weight: 600;
}
.boxFData span,
.boxFData1 span {
  font-size: 15px;
}
.dashh1 h2,
.dashh3 h2 {
  padding-bottom: 10px;
  border-bottom: 1px solid #ebe8e8;
}
.dashh3 h2 {
  text-align: center;
}
.mainBoxNn h4 {
  margin: 15px 0;
  padding-bottom: 10px;
  border-bottom: 1px solid #ebe8e8;
}
.uploadVidBox {
  text-align: center;
}
.uploadVid {
  text-align: center;
  background-color: #eee;
  display: inline-block;
  padding: 15px 20px;
  border-radius: 10px;
  cursor: pointer;
}
.uploadVidC {
  font-size: 30px;
}
.uploadVidBox button {
  font-family: inherit;
  padding: 10px 20px;
  background-color: var(--pepper-red);
  color: #fff;
  border-radius: 100px;
  border: none;
  cursor: pointer;
}
.modalBox {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: #000000b9;
}
.modalContent {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  width: 50%;
  padding: 20px;
  border-radius: 3px;
  height: 80vh;
  overflow: scroll;
  overflow-x: hidden;
}
.mainBox4 {
  flex-direction: column;
  align-items: unset;
  justify-content: center;
  border-bottom: none;
  width: 70%;
  margin: 20px auto;
}
.boxFData4 {
  margin: 10px 0;
  padding-bottom: 10px;
  border-bottom: 1px solid #ebe8e8;
}
.boxBtn1 {
  text-align: center;
  border-bottom: 1px solid #ebe8e8;
  padding-bottom: 10px;
}
.boxBtn1 button {
  padding: 13px 20px;
  font-family: inherit;
  cursor: pointer;
  border-radius: 100px;
  margin: 10px 10px;
  border: none;
  font-weight: 500;
}
.boxBtn3 {
  background-color: var(--pepper-red);
  color: #fff;
}
.modalContent p {
  font-weight: 700;
  text-align: center;
  font-size: 23px;
}
.formMainInput {
  margin: 10px 0;
}
.formMainInput input {
  display: block;
  width: 90%;
  padding: 14px 10px;
  font-size: 14px;
  border: 1px solid #000;
  border-radius: 5px;
}
.formMainInput label {
  font-weight: 600;
  font-size: 13px;
}
.formMainInput textarea {
  display: block;
  width: 90%;
  height: 60px;
  font-family: inherit;
  padding: 10px;
  border: 1px solid #000;
  border-radius: 5px;
  font-size: 14px;
}
.formText {
  margin: 5px 0;
}
.formText span {
  font-size: 14px;
}
.editBtn,
.deleteBtn {
  display: inline-block;
  margin-right: 20px;
  font-family: inherit;
  border-radius: 100px;
  border: none;
  background-color: transparent;
  font-weight: 600;
  cursor: pointer;
}
.deleteBtn {
  color: var(--pepper-red);
}
.imgBox4 video {
  width: 100%;
  height: 300px;
  object-fit: contain;
}
.boxActP {
  margin: 20px 0;
  padding: 20px;
  background-color: #fff;
}
.boxActP p {
  font-size: 14px;
  margin-top: 10px;
}
.boxActP button {
  font-weight: 500;
  padding: 10px 20px;
  border-radius: 100px;
  font-family: inherit;
  border: none;
  cursor: pointer;
}
.VideoYoutubeBox2 {
  position: relative;
  padding-bottom: 56.15%;
  height: 0;
  overflow: hidden;
}
.iframeStyle {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}
.containId input {
  display: block;
  width: 30%;
  border: 1px solid #000;
  font-family: inherit;
  padding: 10px;
}
.containId button {
  background-color: var(--pepper-red);
  padding: 10px 15px;
  color: #fff;
  border: none;
  border-radius: 10px;
  cursor: pointer;
}
.modalContent span {
  font-weight: 600;
}
.uploadBox24 {
  background-color: #eee;
}
.cardform2Vid h2 {
  font-size: 18px;
  margin: 10px 0;
}
.courseVid2Card {
  margin: 10px 0;
}
.formCourseVideos {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 10px;
}
.courseVid2Card h3 {
  font-size: 15px;
}

@media screen and (max-width: 600px) {
  .containerBox {
    padding: 10px;
  }
  .dashh2 h2,
  .dashh1 h2 {
    font-size: 18px;
  }
}

@media screen and (max-width: 1246px) {
  .mainBox1 {
    flex-direction: column;
    align-items: unset;
    justify-content: center;
    border-bottom: none;
  }
  .boxFData1 {
    margin: 10px 0;
    padding-bottom: 10px;
    border-bottom: 1px solid #ebe8e8;
  }
}

@media screen and (max-width: 671px) {
  .imgBox {
    text-align: center;
  }
  .mainBox {
    flex-direction: column;
    align-items: unset;
    justify-content: center;
    border-bottom: none;
  }
  .boxFData {
    margin: 10px 0;
    padding-bottom: 10px;
    border-bottom: 1px solid #ebe8e8;
  }
  .modalContent {
    width: 80%;
  }
  .mainBox4 {
    width: 100%;
  }
  .imgBox4 img {
    width: 95%;
    border-radius: 3px;
  }
  .boxFData4 span {
    font-size: 13px;
  }
  .formMainInput input {
    font-size: 12px;
  }
  .formMainInput textarea {
    font-size: 12px;
  }
  .modalContent p {
    font-size: 18px;
  }
  .uploadVidBox button {
    font-size: 12px;
  }
  .boxBtn1 button {
    font-size: 12px;
    width: 100%;
  }
  .formText span {
    font-size: 12px;
  }
  .containId input {
    width: 90%;
  }
  .imgBox4 video {
    width: 100%;
    height: auto;
    object-fit: unset;
  }
  .courseVid2Card h3 {
    font-size: 15px;
  }
  .formCourseVideos {
    grid-template-columns: repeat(1, 1fr);
  }
  .courseVid2Card {
    margin: 5px 0;
  }
}
