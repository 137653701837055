.storeCard {
  background-color: #fff;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 10px;
}
.storeImg {
  text-align: center;
}
.storeImg img {
  display: block;
  width: 50%;
  height: 160px;
  object-fit: contain;
  margin: 0 auto;
}
.storeText {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 15px 0;
}
.storeText h2 {
  font-size: 15px;
}
.storeText p {
  font-weight: 600;
  font-size: 15px;
}
.storeDesc p {
  font-size: 12px;
  margin-bottom: 15px;
}

@media screen and (max-width: 500px) {
  .storeImg img {
    width: 40%;
  }
}
